import {reactive, toRefs, computed,defineComponent, onMounted, getCurrentInstance,provide} from 'vue';

const TaskCardHelper = defineComponent({
    name:'taskCard',
    title:'定时任务',
    modelType:'card',
    fullscreen: false,
    setup(){
        let {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        provide('EditTableOwner', proxy);
        let dataObj=reactive({
            formRef:null,
            disabled:false,
            refMap:new Map(),
            //卡片传给dialog的初始化参数
            compParams: {
                hsDetails: true,
                details:['taskParams'],
                modelPath: utils.Api.buildUrl("/task")
            },
            //表单属性
            form: {},
            //表单验证规则
            rules:utils.UtilPub.commonFormValidRule([{code:proxy.$t('task.form.code_rule')},{name:proxy.$t('task.form.name_rule')},
                                                              {taskKey:proxy.$t('task.form.taskKey_rule')},{cron:proxy.$t('task.form.cron_rule')}])
        })
        onMounted(()=>{})
        //---------------------------computed------------
        //下拉框初始化参数
        const comboGridParam=computed(()=>{
            return (params:any) => {
                if(params && 'taskKey'==params.comboId){
                    return Object.assign({disabled:dataObj.disabled,formRef:dataObj.formRef,modelMethod:"/task/combo"},params)
                }
            };
        })
        //明细子表格的初始化参数
        const cardDetailParam=computed(()=>{
            return (detailType:string) => {
                return {
                    detailParam: {
                        readOnly:dataObj.disabled,
                        canPage: false,
                        queryParam: {
                            detailType: detailType
                        },
                        modelMethod: utils.Api.buildUrl("/task/detail?t="+Math.random()*1000)
                    }
                }
            }
        })
        const beforeOpen=async(res:any,addOrLoad:string,engineInst:any)=> {

        }
        //明细表格结束编辑某一行的时候，设置了一个显示文本，方便不是编辑状态的情况下显示
        const beforeEndEdit=(options:any)=>{
            dataObj.refMap.get('taskParams').setRowData(options.row, options.index);
            return true;
        }
        return{
            ...toRefs(dataObj),comboGridParam,cardDetailParam,beforeOpen,beforeEndEdit
        }
    }
});
export default TaskCardHelper;